<template>
  <div class="store-control flex--col-center-start">
    <vue-accessible-select
      v-if="localeOptions.length > 0"
      class="store-control__select text-zd-nav text--w-medium noselect"
      :class="{ 'store-control__select--light': light }"
      v-model="activeLocale"
      :options="localeOptions"
      @input="changeLocale"
    >
      <template v-slot:selected="{ option }">{{
        option.value.currencySymbol + " " + option.label
      }}</template>
      <template v-slot:option="{ option }">{{
        option.value.currencySymbol + " " + option.label
      }}</template>
      <template v-slot:arrow>
        <Chevron class="store-control__arrow" />
      </template>
    </vue-accessible-select>
  </div>
</template>
<script>
import { mapState } from "vuex"
import { storeLocales } from "@/store/constants"
import StoreLocaleModal from "@/modules/Modals/StoreLocaleModal"

import Chevron from "@/assets/chevron-down.svg"

export default {
  data: () => ({
    activeLocale: null,
    activeLocaleOption: undefined,
    localeOptions: []
  }),

  props: {
    light: Boolean
  },

  components: {
    Chevron
  },

  mounted() {
    this.initLocale()
  },

  methods: {
    initLocale() {
      this.localeOptions = Object.values(storeLocales).map(locale => ({
        value: locale,
        label: locale.currency
      }))
      this.activeLocale = this.localeOptions.find(
        el => el.value.locale == this.locale
      ).value
    },

    changeLocale({ locale, shopName }) {
      if (locale != this.locale) {
        this.$modal_container
          .launch(StoreLocaleModal, {
            props: {
              targetLocale: locale,
              targetShop: shopName,
              context: "user"
            },
            priority: false
          })
          .catch(() => {
            // if the store change is rejected in the modal
            this.initLocale()
          })
      }
    }
  },

  computed: {
    ...mapState("locale", ["locale", "currency"])
  }
}
</script>
<style lang="scss">
.store-control {
  position: relative;
  pointer-events: all;

  @include media-breakpoint-up(sm) {
    border: unset;
    padding: unset;
  }

  &__select {
    position: relative;

    .v-select {
      &__menu {
        top: 100%;
        cursor: pointer;
        background-color: getcolour(bleach_grey_10);
        padding: 10px 0;
        border-radius: 0;
      }

      &__btn {
        position: relative;
        height: 100%;
        border: none;
        background-color: transparent;
        color: getcolour(bleach_white);
        cursor: pointer;
        white-space: nowrap;
        border-radius: 0;
        border: 2px solid transparent;
        padding: 8px 16px;

        @include media-breakpoint-down(md) {
          padding: 0 6px;
        }

        @include media-breakpoint-down(sm) {
          padding: 10px 6px;
          min-width: 80px;
        }

        &:focus {
          border: 2px solid getcolour(bleach_peach);
          box-shadow: none;
        }
      }

      &__selected {
        text-align: left;
      }

      &__arrow {
        margin-left: 0.5em;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .store-control__arrow {
          width: 1em;
          height: 1em;
          fill: getcolour(bleach_white);
          margin: 0;
          vertical-align: unset;
        }
      }

      &__option {
        margin-bottom: 0;
        white-space: nowrap;

        @include media-breakpoint-down(md) {
          padding: 4px 8px;
        }

        &--selected {
          background: getcolour(bleach_peach);
          color: getcolour(bleach_black);
        }

        &:hover,
        &:focus {
          background: getcolour(bleach_lime);
          color: getcolour(bleach_black);
        }
      }

      @include media-breakpoint-down(md) {
        padding: 0 10px;
      }

      @include media-breakpoint-down(sm) {
        padding: 10px 30px;

        &__arrow {
          margin-left: 5px;
        }
      }
    }

    &--light {
      .v-select {
        // &__menu {
        //   color: getcolour(bleach_black);
        //   background-color: getcolour(bleach_grey_80);
        // }

        &__btn {
          color: getcolour(bleach_black);
        }

        &__arrow {
          .store-control__arrow {
            fill: getcolour(bleach_black);
          }
        }
      }
    }
  }

  &__arrow {
    .v-select--opened & {
      transform: rotate(180deg);
    }
  }

  &__store-string {
    text-align: left;
    display: inline-block;
    border-right: 1px solid getcolour(bleach_white);
    padding-right: 1em;
    margin-right: 1em;
  }
}
</style>
